<template>
  <v-row>
    <v-col cols="6" sm="12" md="6">
      <DbmDatePicker v-model="modelLocal.validFrom" :dateLabel="$t('create_product_status_validity_dates_valid_from')" :required="true" />
    </v-col>
    <v-col cols="6" sm="12" md="6">
      <DbmDatePicker v-model="modelLocal.validUntil" :dateLabel="$t('create_product_status_validity_dates_valid_until')" :clearable="true" />
    </v-col>
    <v-col cols="6" sm="12" md="6">
      <v-text-field
        id="ancestor"
        :label="$t('create_product_status_validity_dates_ancestor_id')"
        :error-messages="this.errorMessage"
        v-model="query.code"
        @blur="searchProduct()"
      />
    </v-col>
    <v-col cols="6" sm="12" md="6">
      {{ this.chosenProduct[this.$getLangKey()] }}
    </v-col>
    <v-col cols="6" sm="12" md="6">
      <v-switch
        id="create_product_status_validity_dates_publishable"
        v-model="modelLocal.publishable"
        color="primary"
        :label="$t('create_product_status_validity_dates_publishable')"
      ></v-switch>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { showError } from '@/services/axios'
import BaseMixin from '../../../utils/BaseMixin'
import DbmDatePicker from '@/components/dbmDatePicker.vue'

import { defineComponent } from 'vue'
export default defineComponent({
  data() {
    return {
      chosenProduct: {},
      errorMessage: '',
      rules: {
        required: (value) => !!value || this.$t('form_json_schema_required_field')
      }
    }
  },
  props: ['model'],
  computed: {
    modelLocal: {
      get: function () {
        return this.model
      },
      set: function (value) {
        this.$emit('update', value)
      }
    }
  },
  mixins: [BaseMixin],
  components: {
    DbmDatePicker: DbmDatePicker
  },
  methods: {
    async searchProduct() {
      this.isBusy = true
      const apiUrl = this.apiURL
      if (this.query.code == '' || this.query.code == undefined || this.query.code == null) {
        this.chosenProduct = {}
      } else {
        try {
          // #/de/products/:id
          // id == create ? file : axios ..
          this.items = []
          const response = await this.axios.get(apiUrl, {
            params: this.query
          })
          this.isBusy = false
          const items = await response.data.content
          this.totalElements = this.checkPage(response.data.totalElements)
          this.items = Array.isArray(items) ? items : []
          if (this.totalElements === 0) {
            this.chosenProduct = {}
            this.errorMessage = this.$t('status_validity_dates_not_valid_product_id')
          } else {
            this.chosenProduct = await response.data.content[0]
            this.modelLocal.ancestor = { id: response.data.content[0].id }
            this.errorMessage = ''
          }
        } catch (e) {
          showError(e)
        }
        return true
      }
    }
  }
})
</script>
