<template>
  <v-container grid-list-xl>
    <h1 class="text-left product-title">
      <span v-html="$t('product_title')" /> {{ this.model.code }}
      {{ this.model[this.$getLangKey()] }}
    </h1>

    <v-form id="productForm" ref="productForm" @submit.prevent="submit()">
      <v-row>
        <!-- Bezeichnung -->
        <v-col class="item card-spacing" cols="12" md="6">
          <h2 v-html="$t('product_designation')"></h2>
          <ProductDescription v-model:model="model" :readonly="false" />
        </v-col>
        <!-- Status/ Gültigkeit -->
        <v-col class="item card-spacing" cols="12" md="6">
          <h2 v-html="$t('product_status_validity')"></h2>
          <CreateStatusValidityDates v-model:model="model" />
        </v-col>

        <!-- Tierart -->
        <v-col class="item card-spacing" cols="12" md="6">
          <h2 v-html="$t('product_animal_types')"></h2>
          <div class="animalTypesContainer">
            <v-row>
              <v-col cols="6" sm="12" md="6" lg="6">
                <v-checkbox v-model="model.animalTypeCow" :label="$t('cow')"></v-checkbox>
                <v-checkbox v-model="model.animalTypeBuffalo" :label="$t('buffalo')"></v-checkbox>
                <v-checkbox v-model="model.animalTypeGoat" :label="$t('goat')"></v-checkbox>
                <v-checkbox v-model="model.animalTypeSheep" :label="$t('sheep')"></v-checkbox>
              </v-col>
            </v-row>
          </div>
        </v-col>
        <!-- Kategorisierung -->
        <v-col class="item card-spacing" sm="12" md="6">
          <h2 v-html="$t('product_categorization')"></h2>
          <div class="error-message">{{ errorMessage }}</div>

          <ProductGroupTreeView v-model="model.productGroupMain.id" :rootId="1000" :title="$t('hauptgruppe')" :editable="true" />

          <ProductGroupTreeView v-model="model.productGroupEuroStatistic1.id" :rootId="5000" title="EuroStatistic1" :editable="true" />

          <ProductGroupTreeView v-model="model.productGroupEuroStatistic2.id" :rootId="6000" title="EuroStatistic2" :editable="true" />

          <ProductGroupTreeView v-model="model.productGroupSwissStatistic.id" :rootId="8000" title="SwissStatistic" :editable="true" />
        </v-col>
        <!-- Allgemeine Gehaltswerte -->
        <v-col class="item card-spacing" sm="12" md="6" v-if="isRawMaterial">
          <h2 v-html="$t('product_raw_material')"></h2>
          <RawMaterialDetails v-model:model="model" />
        </v-col>

        <v-col class="item card-spacing" sm="12" md="6" v-if="isCheese">
          <h2 v-html="$t('product_cheese_details')"></h2>
          <CheeseDetails v-model:model="model" />
        </v-col>

        <v-col class="item card-spacing" sm="12" md="6" v-if="isCheese">
          <h2 v-html="$t('product_cheese_values')"></h2>
          <CheeseValues v-model:model="model" />
        </v-col>

        <v-col class="item card-spacing" sm="12" md="6">
          <h2 v-html="$t('product_general_content')"></h2>
          <GeneralNutritionalValues v-model="model" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="12" lg="12">
          <v-btn color="secondary shadow-none" class="button-margin" @click="goBack"><span v-html="$t('create_product_back_button')" /></v-btn>
          <v-btn color="primary shadow-none" class="button-margin" type="submit"><span v-html="$t('save')" /></v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>
<script lang="ts">
import _ from 'lodash'
import BaseMixin from '../../../utils/BaseMixin'
import { apiURL, emitter } from '@/main'
import { showError } from '@/services/axios'
import createProductExample from './schemas/createProductExample.json'
import CreateStatusValidityDates from './createStatusValidityDates'
import ProductDescription from './productDescription'
import ProductGroupTreeView from '../productGroups/productGroupTreeView.vue'
import RawMaterialDetails from './rawMaterialDetails'
import CheeseDetails from './cheeseDetails'
import CheeseValues from './cheeseValues'
import GeneralNutritionalValues from './generalNutritionalValues'

//https://www.npmjs.com/package/vue-form-schema
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'createProduct',
  data() {
    return {
      allItems: [],
      totalElements: 0,
      fields: [
        { key: 'creation', sortable: true },
        { key: 'creatorId', sortable: true },
        { key: 'guid', sortable: true },
        { key: 'hyid', sortable: true },
        { key: 'id', sortable: true },
        { key: 'ident', sortable: true },
        { key: 'modification', sortable: true },
        { key: 'modifierId', sortable: true },
        { key: 'nameDe', sortable: true },
        { key: 'nameFr', sortable: true },
        { key: 'nameIt', sortable: true },
        { key: 'parentId', sortable: true },
        { key: 'pos', sortable: true },
        { key: 'rootId', sortable: true },
        { key: 'valid_until', sortable: true }
      ],
      term: { nameDE: '', pos: '' },
      model: _.clone(createProductExample),
      animalTypes: [],
      selectedAnimalType: undefined,
      selected: undefined,
      valid: false,
      errorMessage: ''
    }
  },
  mixins: [BaseMixin],
  methods: {
    submitAndClose() {
      this.form.submit()
      this.goBack()
    },
    goBack() {
      this.$router.go(-1)
    },
    async submit() {
      if ((await this.$refs.productForm.validate()).valid) {
        if (this.model.productGroupMain && this.model.productGroupMain.id !== 0) {
          this.model.productGroupMain = { id: this.model.productGroupMain.id }
          this.errorMessage = ''
        } else {
          this.errorMessage = this.$t('create-product-error-no-main-product-group')
          window.scrollTo(0, 0)
          return
        }
        if (this.model.productGroupEuroStatistic1?.id === 0) {
          this.model.productGroupEuroStatistic1 = undefined
        } else if (this.model.productGroupEuroStatistic1) {
          this.model.productGroupEuroStatistic1 = {
            id: this.model.productGroupEuroStatistic1.id
          }
        }
        if (this.model.productGroupEuroStatistic2?.id === 0) {
          this.model.productGroupEuroStatistic2 = undefined
        } else if (this.model.productGroupEuroStatistic2) {
          this.model.productGroupEuroStatistic2 = {
            id: this.model.productGroupEuroStatistic2.id
          }
        }
        if (this.model.productGroupSwissStatistic?.id === 0) {
          this.model.productGroupSwissStatistic = undefined
        } else if (this.model.productGroupSwissStatistic) {
          this.model.productGroupSwissStatistic = {
            id: this.model.productGroupSwissStatistic.id
          }
        }
        try {
          const result = await this.axios.post(
            apiURL + '/products/',
            _.pick(this.model, _.keys(createProductExample), {
              headers: { 'Content-Type': 'application/json' }
            })
          )
          if (await result) {
            emitter.emit('toastSuccess', {
              message: this.$t('toast_product_created_message')
            })
            this.$router.push({ name: 'product_write', params: { id: result.data.id } }).catch(() => {})
            this.errorMessage = ''
          }
        } catch (e) {
          showError(e)
        }
      } else {
        window.scrollTo(0, 0)
      }
    },
    reset() {
      // im a hook
    }
  },
  computed: {
    isRawMaterial() {
      return this.model.productGroupMain.parentId === 12
    },
    isCheese() {
      return this.model.productGroupMain.parentId === 11
    }
  },
  components: {
    ProductDescription: ProductDescription,
    CreateStatusValidityDates: CreateStatusValidityDates,
    ProductGroupTreeView: ProductGroupTreeView,
    RawMaterialDetails: RawMaterialDetails,
    CheeseDetails: CheeseDetails,
    CheeseValues: CheeseValues,
    GeneralNutritionalValues: GeneralNutritionalValues
  },
  watch: {
    model: {
      handler(to) {
        // calculated fields https://tsmtreuhand.atlassian.net/wiki/spaces/dbmilch2020/pages/245694475/Milchverwertung+Produkte+Produkt
        to.equivalentMin3 = to.equivalentMin?.toFixed(3)
        to.equivalentMax3 = to.equivalentMax?.toFixed(3)
        to.equivalentAvg3 = to.equivalentAvg?.toFixed(3)
      },
      deep: true
    }
  }
})
</script>

<style lang="scss" scoped>
@import '@/styles/colors.scss';
@import '@/styles/variables.scss';

option:disabled {
  background-color: rgba(192, 192, 192, 0.788);
}

.product-title {
  margin-bottom: $spacer-xs !important;
}

.card-spacing {
  padding-top: $spacer-xxxs !important;
  padding-bottom: $spacer-xxxs !important;
}

.button-margin {
  margin-right: $spacer-xs !important;
}

.error-message {
  color: $dbm-red;
}
</style>
